import React, { Component } from "react";
import axios from "axios";

class ContactFormEN extends Component {
    constructor() {
        super();
        this.state = {
            name: null,
            email: null,
            text: null,

            errors: {
                name: "",
                email: "",
                text: "",
            },

            loadingRequest: false,
            requestMsg: null,
        };
    }

    handleInputChange = event => {
        // eslint-disable-next-line no-use-before-define
        // eslint-disable-next-line no-useless-escape
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

        event.preventDefault();
        const { name, value } = event.target;

        let errors = this.state.errors;

        switch (name) {
            case "name":
                errors.name = value.length < 1 ? "This field is required!" : "";
                break;

            case "email":
                errors.email = validEmailRegex.test(value) ? "" : "Email is not valid!";
                break;

            case "text":
                errors.text = value.length < 1 ? "This field is required!" : "";
                break;

            default:
                break;
        }

        this.setState({ errors, [name]: value }, () => {});
    };

    validateForm = errors => {
        let valid = true;
        Object.values(errors).forEach(val => val.length > 0 && (valid = false));
        if (this.state.name === null && this.state.email === null && this.state.text === null) {
            valid = false;
        }
        return valid;
    };

    submitContact = event => {
        event.preventDefault();
        this.setState({ loadingRequest: !this.state.loadingRequest });
        if (this.validateForm(this.state.errors)) {
            let bodyFormData = new FormData();

            bodyFormData.set("name", this.state.name);
            bodyFormData.set("email", this.state.email);
            bodyFormData.set("text", this.state.text);

            axios
                .post(`https://api.whitebrim.co/api/project/592d4cf5b549b452a450d829/account/email`, bodyFormData)
                // .post(`http://localhost:4001/api/project/592d4cf5b549b452a450d829/account/email`, bodyFormData)
                .then(result => {
                    if (result.status === 200) {
                        this.setState({
                            name: "",
                            email: "",
                            text: "",
                            loadingRequest: !this.state.loadingRequest,
                            requestMsg: "Message sent!",
                        });
                        setTimeout(() => {
                            this.setState({ requestMsg: null });
                        }, 3500);
                    }
                })
                .catch(error => {
                    this.setState({
                        name: "",
                        email: "",
                        text: "",
                        loadingRequest: !this.state.loadingRequest,
                        requestMsg: "An error ocurred, try again later!",
                    });
                    setTimeout(() => {
                        this.setState({ requestMsg: null });
                    }, 3500);
                });
        } else {
            this.setState({ loadingRequest: false });
            if (this.state.name === null && this.state.email === null && this.state.text === null) {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        name: "This field is required!",
                        email: "This field is required!",
                        text: "This field is required!",
                    },
                });
            }
        }
    };

    render() {
        const { errors } = this.state;

        return (
            <div className="contact-form">
                <form className="form-style--1 wow move-up" onSubmit={this.submitContact}>
                    <div className="row">
                        <div className={`${this.props.contactUs ? `col-lg-12` : `col-lg-6`}`}>
                            <div className={`input-box mb-10`}>
                                <span className="form-icon far fa-user"></span>
                                <input type="text" placeholder="Name *" name="name" className={`${errors.name.length > 0 && `input-error`}`} value={this.state.name} onChange={this.handleInputChange} />
                            </div>
                            <span className="form-error ml-1">
                                {errors.name.length > 0 && (
                                    <>
                                        <i className="fas fa-info-circle"></i> {errors.name}
                                    </>
                                )}
                            </span>
                        </div>
                        <div className={`${this.props.contactUs ? `col-lg-12` : `col-lg-6`}`}>
                            <div className={`input-box mb-10`}>
                                <span className="form-icon far fa-envelope"></span>
                                <input type="text" placeholder="Email *" name="email" className={`${errors.email.length > 0 && `input-error`}`} value={this.state.email} onChange={this.handleInputChange} />
                            </div>
                            <span className="form-error ml-1">
                                {errors.email.length > 0 && (
                                    <>
                                        <i className="fas fa-info-circle"></i> {errors.email}
                                    </>
                                )}
                            </span>
                        </div>
                        <div className="col-lg-12">
                            <div className={`input-box mb-10`}>
                                <textarea placeholder="Your message *" name="text" className={`${errors.text.length > 0 && `input-error`}`} value={this.state.text} onChange={this.handleInputChange}></textarea>
                            </div>
                            <span className="form-error ml-1">
                                {errors.text.length > 0 && (
                                    <>
                                        <i className="fas fa-info-circle"></i> {errors.text}
                                    </>
                                )}
                            </span>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-action">
                                {!this.props.contactUs && (
                                    <div className="form-description">
                                        We're available for commissions and collaborations, and
                                        <br />
                                        excited to hear from you about new projects
                                    </div>
                                )}
                                {!this.props.contactUs ? (
                                    <div className="form-submit">
                                        <input type="submit" value={this.state.loadingRequest ? "Sending" : this.state.requestMsg !== null && !this.state.loadingRequest ? this.state.requestMsg : "Send message"} disabled={this.state.loadingRequest} />
                                    </div>
                                ) : (
                                    <input type="submit" value={this.state.loadingRequest ? "Sending" : this.state.requestMsg !== null && !this.state.loadingRequest ? this.state.requestMsg : "Send message"} disabled={this.state.loadingRequest} />
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default ContactFormEN;
